import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="पांडुरंग शास्त्री आठवले स्तवन" />
        <HeaderGeneric title="इतर साहित्य" adhyaay='पांडुरंग शास्त्री आठवले स्तवन'/>
        <div id="main">
          <section id="content" className="main">
            <p>
॥ श्री गजानन महाराज प्रसन्न ॥< br />
॥ श्री आठवले गुरू प्रसन्न ॥< br />
< br />
गुरुवार, १ मार्च १९९०.< br />
< br />
(येथे पांडुरंग म्हणजे पांडुरंग शास्त्री आठवले)< br />
< br />
पांडुरंग पांडुरंग । तुझ्या नामात दंग ।< br />
तेणे घडे सत्संग । शुद्ध अंतरंग ॥< br />
< br />
पांडुरंग नामात । जीव रमु दे त्यात ।< br />
येता जाता भजण्यात । भाव राहु दे मनात ॥< br />
< br />
पांडुरंग मुखे वदता । प्रपंच ताप न चित्ता ।< br />
येता जाता भजता । स्वस्थता अनुभवता ॥< br />
< br />
नाही आनंद विषयात । आनंद आहे नामात ।< br />
पांडुरंग जयघोषात । विषय दूर जातात ॥< br />
< br />
पांडुरंग पांडुरंग । येता जाता पांडुरंग ।< br />
ऐसे नामात होता दंग । येता जाता सत्संग ॥< br />
< br />
पांडुरंग सहाय्यभूत । पांडुरंग उपयुक्त ।< br />
तेणे राहु दे नामात । हीच इच्छा मनात ॥< br />
< br />
ज्या ज्या स्थळी जाईन । पांडुरंगच पाहीन ।< br />
येता जाता होवो भजन । पांडुरंगी सदा लीन ॥< br />
< br />
माझी भूक तहान । पांडुरंगे तोषवीन ।< br />
नामात दंग होऊन । तेणे संतुष्ट होऊन ॥< br />
< br />
पांडुरंगा आळवीन । येता जाता नामस्मरण । < br />
ऐसी हाक मारेन । पांडुरंग येईल धावुन ॥< br />
< br />
पांडुरंगावर विश्वास । प्रचिती आली मनास ।< br />
त्याला हाक मारल्यास । उद्धरणार हमखास ॥< br />
< br />
पांडुरंगाच्या नामात । आनंद जडला त्यात ।< br />
येता जाता भजण्यात । आनंदी आनंद मनात ॥< br />
< br />
पांडुरंगाच्या नामाने । प्रसन्नता खात्रीने । < br />
जगु आत्मविश्वासाने । पटते अनुभवाने ॥< br />
< br />
पांडुरंगा माझ्या सदना । लाभु द्या गुरुचरणा ।< br />
माझा मनोध्यास जाणा । त्वरीत तुम्ही आणा ॥< br />
< br />
पांडुरंगा तुझ्याविण । नाही गुरुचरण ।< br />
तुझे स्मरण करीन । गुरू पादुका मिळविन ॥< br />
< br />
पांडुरंगा तुझ्या नामात । अत्यंत विश्वसलो त्यात ।< br />
काव्य शक्ती लाभण्यात । खरा उपयुक्त त्यात ॥< br />
< br />
पांडुरंगा माझे ऐका । नीट कान देऊन ऐका ।< br />
धाडा चांदीच्या पादुका । गजा अवलीयाच्या ॥< br />
< br />
पांडुरंगा तूच धाव आता । जाण माझी मनोव्यथा ।< br />
गुरू पादुका मागता । काय गुन्हा झाला सांगता! ॥< br />
< br />
पांडुरंगाच्या नामात । खरा स्वाध्याय त्यात ।< br />
पांडुरंग स्मरणात । खरे खुरे ज्ञान त्यात ॥< br />
< br />
पांडुरंग पांडुरंग । सर्वात दिसे पांडुरंग ।< br />
तुझ्या रूपात होता दंग । सर्वकाळी सत्संग ॥< br />
< br />
पांडुरंगा तुझ्यावर । प्रपंच सोपवणार ।< br />
तुझेच नाम भजणार । मनःशांतीने जगणार ॥< br />
< br />
पांडुरंगा पांडुरंगा । काय करू सांगा ।< br />
म्हणता विषय त्यजा । कैसे त्यजु सांगा ॥< br />
< br />
पांडुरंगा तुझ्या नामात । जरी मी दंग त्यात ।< br />
वृत्ती सतावतात । परावृत्त करतात ॥< br />
< br />
येता जाता पांडुरंगात । वेळेचे न भान त्यात ।< br />
कर्तव्यकर्मे न होतात । नको दोष मला त्यात ॥< br />
< br />
पांडुरंगा! आजवर । विश्वासलो तुझ्यावर ।< br />
तुच मला तारणार । पुन्हां पुन्हां हांक मारणार ॥< br />
< br />
पांडुरंगा तुझ्या नामात । काय न मिळे त्यात ।< br />
भिकारी येता दारात । संतुष्टतो चित्तात ॥< br />
< br />
पांडुरंगा तुझ्या नामात । सर्व सर्व आहे त्यात ।< br />
स्त्रीसुखापेक्षा जास्त त्यात । आहे पांडुरंगात ॥< br />
< br />
पांडुरंग माझा सखा । जाणतो माझ्या दुःखा ।< br />
नामात तरशील लेका! । येता जाता सांगे सखा ॥< br />
< br />
पांडुरंग आत्मसुख । जाळतो भवदुःख ।< br />
शांतीचा मार्ग एकेक । दावतो एक एक ॥< br />
< br />
पांडुरंग पांडुरंग । तुझ्या नामात झालो दंग ।< br />
सदा जडो सत्संग । जाण माझे अंतरंग ॥< br />
< br />
पांडुरंग ऐसे नाम । सत्संगाचे धाम ।< br />
येता जाता भजता नाम । साधुसंत मुक्काम ॥< br />
< br />
पांडुरंगा राखा लाज । भजन केले तुझे आज ।< br />
माझी राखण्यात लाज । तुझीच राहील लाज ॥< br />
< br />
पांडुरंगा मी अज्ञानी । मंदमती अवगुणी ।< br />
तू तर  गुणी, ज्ञानी । नामात साधेन दोन्ही ॥< br />
< br />
पांडुरंगा तुझ्या नामात । अमर्याद ज्ञान त्यात ।< br />
येता जाता भजण्यात । ज्ञानी होऊन जगण्यात ॥< br />
< br />
पांडुरंग नामात । अंतर्मुखे जगण्यात ।< br />
आपलेच दोष कळतात । येता जाता भजण्यात ॥< br />
< br />
नको दोष दुसर्‍याचे । दोष पहावे स्वतःचे ।< br />
दूर करणे हीताचे । पांडुरंग बोले वाचे ॥< br />
< br />
पांडुरंगाच्या नामात । दृष्टीदोष कळतात ।< br />
स्वयेच जागृत होण्यात । अनेक फायदे त्यात ॥< br />
< br />
- गणामहाराज
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
